@if (timeOptions && timeOptions.length > 0) {
  @if (isList) {
    <div class="selection-list-time-picker">
      <mat-selection-list
        #timePicker
        [multiple]="false"
        [(ngModel)]="value"
        (selectionChange)="onTimeChange($event)"
        class="selection-wrapper custom-scrollbar"
        [class.smaller-selection]="size === SelectionSize.SMALL"
        disableRipple
      >
        <mat-list-option
          *ngFor="let timeOption of timeOptions"
          [value]="timeOption"
          [class.selected-value]="timeOption === this.value"
          class="option"
          >{{
            format24Hours ? timeOption : format12Hour(timeOption)
          }}</mat-list-option
        >
      </mat-selection-list>
    </div>
  } @else {
    <div class="time-picker-container">
      <mat-form-field appearance="outline">
        <mat-label>{{ label | translate }}</mat-label>
        <mat-select
          [(ngModel)]="value"
          [disabled]="disabled"
          (selectionChange)="onTimeChange($event)"
          placeholder="HH:mm"
        >
          @for (timeOption of timeOptions; track timeOption) {
            <mat-option [value]="timeOption">{{
              format24Hours ? timeOption : format12Hour(timeOption)
            }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  }
}
