import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatListModule, MatSelectionList } from '@angular/material/list';
import { FormsModules } from '@utils/shared-modules';
import { TranslateModule } from '@ngx-translate/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export enum SelectionSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

@Component({
  selector: 'app-time-picker',
  standalone: true,
  imports: [CommonModule, FormsModules, MatListModule, TranslateModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimePickerComponent),
      multi: true,
    },
  ],
  templateUrl: './time-picker.component.html',
  styleUrl: './time-picker.component.scss',
})
export class TimePickerComponent
  implements OnInit, AfterViewInit, ControlValueAccessor
{
  @Input() interval: number = 30; // Time interval in minutes
  @Input() format24Hours: boolean = true; // Use 24h format
  @Input() minTime: string = '00:00';
  @Input() maxTime: string = '23:59';
  @Input() label: string = '';
  @Input() isList: boolean = true;
  @Input() size: SelectionSize = SelectionSize.MEDIUM;

  value: string = '';

  onChange = (value: string) => {};

  onTouched = () => {};

  touched = false;

  disabled = false;

  timeOptions: string[] = [];

  @ViewChild('timePicker') timePicker?: MatSelectionList;

  ngOnInit() {
    this.initializeTimeOptions();
  }

  ngAfterViewInit() {
    if (this.isList) {
      this.scrollToSelected(this.value);
    }
  }

  initializeTimeOptions() {
    const startHour = this.getHour(this.minTime);
    const endHour = this.getHour(this.maxTime);
    const startMinute = this.getMinute(this.minTime);
    const endMinute = this.getMinute(this.maxTime);

    const hours = Array.from(
      { length: endHour - startHour + 1 },
      (_, i) => startHour + i,
    );
    const minutes = Array.from(
      { length: Math.floor(60 / this.interval) },
      (_, i) => i * this.interval,
    );

    this.timeOptions = hours
      .map((hour) =>
        minutes.map(
          (minute) => `${this.formatHour(hour)}:${this.padZero(minute)}`,
        ),
      )
      .reduce((acc, val) => acc.concat(val), []);

    if (startMinute > 0) {
      this.timeOptions.shift();
    }
    if (startMinute > 30) {
      this.timeOptions.shift();
    }
    if (endMinute < 30) {
      this.timeOptions.pop();
    }
  }

  getHour(time: string): number {
    return parseInt(time.split(':')[0], 10);
  }

  getMinute(time: string): number {
    return parseInt(time.split(':')[1], 10);
  }

  formatHour(hour: number): string {
    return this.padZero(hour);
  }

  format12Hour(time24: string): string {
    const [sHours, minutes] = time24.split(':');
    const period = +sHours < 12 ? 'AM' : 'PM';
    const hours = +sHours % 12 || 12;

    return `${this.padZero(hours)}:${minutes} ${period}`;
  }

  padZero(num: number): string {
    return num < 10 ? `0${num}` : num.toString();
  }

  onTimeChange(change: any) {
    this.markAsTouched();
    if (!this.disabled) {
      this.value = this.isList ? change.options[0]._value : change.value;
      this.onChange(this.value);
    }
  }

  writeValue(value: string) {
    this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  scrollToSelected(timeOption: string) {
    if (!this.timePicker || !document) {
      return;
    }

    if (timeOption === '') {
      timeOption = '12:00';
    }

    const option = this.timePicker.options.find((v) => v.value === timeOption);

    if (option) {
      const topPos = option._hostElement.offsetTop;
      const selectionWrapper = this.timePicker._element.nativeElement;
      if (selectionWrapper) {
        selectionWrapper.scrollTop = topPos - 28;
      }
    }
  }

  protected readonly SelectionSize = SelectionSize;
}
